import { useReactiveVar } from "@apollo/client";

import {
  userData,
  rulesData,
  familyData,
  pageCity,
  pageCountry,
} from "../../../graphql/reactivities";

export const useUserData = () => useReactiveVar(userData);
export const useRulesData = () => useReactiveVar(rulesData);
export const useFamilyData = () => useReactiveVar(familyData);
export const usePageCity = () => useReactiveVar(pageCity);
export const usePageCountry = () => useReactiveVar(pageCountry);
