import {
  from,
  InMemoryCache,
  ApolloClient,
  Observable,
  toPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

import { isServer, useAuth } from "./auth";

// import { REFRESH_TOKEN } from "../graphql/mutations";
import { useMemo } from "react";
import merge from "deepmerge";
import isEqual from "lodash.isequal";
import {
  GET_KEY_CLUB,
  GET_PAGE_LANGUAGE,
  keyClub,
  // GET_TOKENS,
  // tokens,
  pageLanguage,
  tokensDefault,
  userData,
  userDefault,
  pageCity,
  pageCountry,
} from "../graphql/reactivities";

import { setContext } from "@apollo/client/link/context";
import Router from "next/router";
import { createUploadLink } from "apollo-upload-client";
import { API_URL } from "../constants/APIKeys";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient = null;
let isRefreshing = false;

const requestForRefetch = [];

const getAuthToken = () => {
  if (isServer()) return tokensDefault;
  const storage = window.localStorage.getItem("tokens");

  if (storage) return JSON.parse(storage) ?? tokensDefault;

  return tokensDefault;
};

const getUserData = () => {
  if (isServer()) return;
  const storage = window.localStorage.getItem("userData");

  if (storage) return userData(JSON.parse(storage) ?? userDefault);
};

const getPageCity = () => {
  if (isServer()) return;
  const storage = window.localStorage.getItem("pageCity");

  if (storage) return pageCity(JSON.parse(storage) ?? "");
};

const getPageCountry = () => {
  if (isServer()) return;
  const storage = window.localStorage.getItem("pageCountry");

  if (storage) return pageCountry(JSON.parse(storage) ?? "");
};

const redirectAndLogOut = (key, signOut) => {
  requestForRefetch.length = 0;

  isRefreshing = false;
  if (key) Router.replace(`/club/${key}`);
  signOut?.();
};

const createApolloClient = (initialState = {}) => {
  // const tokensData = getAuthToken();
  getUserData();
  getPageCity();
  getPageCountry();
  const {
    key = "",
    locale = "ru",
    auth: { signIn, signOut } = {},
  } = initialState;

  const cache = new InMemoryCache({
    typePolicies: {
      // Activity: {
      //   merge: true,
      // },
      // ActivityType: {
      //   merge: true,
      // },
      // City: {
      //   merge: true,
      // },
      // CityType: {
      //   merge: true,
      // },
      Query: {
        fields: {
          // authTokens: {
          //   read() {
          //     return tokens(tokensData);
          //   },
          // },
          keyClub: {
            read() {
              return keyClub(key);
            },
          },
          pageLanguage: {
            read() {
              return pageLanguage(locale);
            },
          },
          // family: {
          //   read(family, data) {
          //     return family;
          //   },
          // },
          HallType: {
            merge: true,
          },
          ClubActivityType: {
            merge: true,
          },
        },
      },

      InstasportSettingsType: {
        merge: true,
      },
      ClubActivityType: {
        merge: true,
      },
      // InstructorDetailType: {
      //   merge: false,
      // },
      // HallType: { merge: false },
      ClubType: {
        merge: true,
      },
    },
  }).restore(initialState);

  const httpLink = new createUploadLink({
    uri: API_URL,
    // credentials: "same-origin",
    // credentials: "include",
  });

  const retryLink = new RetryLink({
    // attempts: (count, operation, error) => {
    //   return !!error && operation.operationName != "specialCase";
    // },
    attempts: {
      max: 2,
      retryIf: (error, operation) => {
        const cb = (op) => op.operationName === operation.operationName;

        if (
          [
            { operationName: "TokenRefresh" },
            { operationName: "AllUserData" },
            { operationName: "User" },
            { operationName: "Announces" },
            { operationName: "Club" },
            // { operationName: "Portal" },
          ].some(cb) ||
          requestForRefetch.some(cb)
          // ||
          // operation?.query?.definitions[0].operation === "mutation"
        )
          return false;
        console.log(
          error?.statusCode,
          // error,
          // error?.graphQLErrors,
          operation.operationName
        );
        if (operation?.query?.definitions[0].operation === "mutation")
          return true;
        return error?.statusCode === 401;
        // && error?.result?.errors[0]?.result !== 1
      },
    },
    delay: {
      initial: 1000,
      // max: Infinity,
      // jitter: true,
    },
  });

  // const handlerLink = new ApolloLink((operation, forward) => {
  //   console.log("handlerLink", operation);
  //   if (isRefreshing) {
  //     requestForRefetch.push(operation);
  //     return;
  //   }
  //   return forward(operation);
  // });

  const getAuthHeaders = ({ isOnlyTokenHeaders = false } = {}) => {
    // const {
    //   authTokens: { accessToken: authToken },
    // } = cache.readQuery({ query: GET_TOKENS });
    const { keyClub: key = "" } = cache.readQuery({
      query: GET_KEY_CLUB,
    });
    const { pageLanguage: language = "ru" } = cache.readQuery({
      query: GET_PAGE_LANGUAGE,
    });

    const { accessToken } = getAuthToken();

    const apiKey = accessToken
      ? `Token ${accessToken}`
      : `Key ${process.env.API_DEFAULT_KEY}`;

    const headers = {
      Authorization: `${apiKey}${key ? ` ${key}` : ""}`,
      "Accept-Language": language,

      ...(isOnlyTokenHeaders && {
        "Content-Type": "application/json",
      }),
    };

    return headers;
  };

  const authLink = setContext((_, { headers }) => {
    const isRefresh = !!headers?.isRefresh;
    if (isRefresh) delete headers.isRefresh;

    return {
      headers: {
        ...getAuthHeaders(),
        ...(isRefresh && headers),
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const { message, locations, path, result } of graphQLErrors) {
          if (!isServer()) {
            if (result === 5 || result === 1) {
              // result === 1 && isRefreshing

              //! Mutation not fire only with ErrorLink

              const { accessToken, refreshToken, exp = 0 } = getAuthToken();
              // console.log(isRefreshing, "isRefreshing");

              if (!isRefreshing) {
                // console.log(isRefreshing, "isRefreshing");

                // const isExpired = Date.now() > exp;

                // if (!isExpired) return;
                isRefreshing = true;
                if (
                  !["User", "AllUserData"].some(
                    (op) => op === operation.operationName
                  ) &&
                  operation.query.definitions[0].operation !== "mutation"
                ) {
                  // console.log(exp, "Date.now() >= exp");
                  // if (isExpired)
                  requestForRefetch.push(operation);
                  // else return;
                }
              } else return;

              // {
              //   console.log(operation.operationName,'operation.operationName')
              //   if (Date.now() >= exp) {
              //     requestForRefetch.push(operation);
              //   }
              //   return;
              // }

              if (!accessToken) return redirectAndLogOut(key, signOut);

              const promise = fetch(API_URL, {
                method: "post",

                headers: {
                  Authorization: `Refresh ${refreshToken}`,
                  "Content-Type": "application/json",
                },

                body: JSON.stringify({
                  query: `mutation TokenRefresh {
                      tokenRefresh {
                        token {
                          accessToken
                          refreshToken
                        }
                      }
                    }`,
                }),
              })
                .then((r) => r.json())
                .then((response) => {
                  // console.log(" response on token update", response);
                  if (response.data) {
                    const { data } = response;
                    const accessToken = data?.tokenRefresh?.token?.accessToken;

                    const tokensData = {
                      accessToken,
                      refreshToken: data?.tokenRefresh?.token?.refreshToken,
                    };

                    window.localStorage.setItem(
                      "tokens",
                      JSON.stringify({
                        ...tokensData,
                        exp: Date.now() + 5 * 60 * 1000,
                        isAuthorized: true,
                      })
                    );
                    signIn(tokensData, false);

                    return { accessToken };
                  }

                  return redirectAndLogOut(key, signOut);
                })
                .catch(() => {
                  return redirectAndLogOut(key, signOut);
                });

              return promiseToObservable(promise).flatMap(({ accessToken }) => {
                // console.log("???");
                if (accessToken) {
                  // operation.setContext(({ headers }) => ({
                  //   headers: {
                  //     ...headers,
                  //     Authorization: `Token ${accessToken}${
                  //       key ? ` ${key}` : ""
                  //     }`,
                  //   },
                  // }));

                  // return forward(operation);

                  requestForRefetch
                    .reduce((acc, op) => {
                      return acc.then(async (_) => {
                        // console.log("op", op?.operationName);
                        op.setContext(({ headers }) => ({
                          headers: {
                            ...headers,
                            Authorization: `Token ${accessToken}${
                              key ? ` ${key}` : ""
                            }`,
                          },
                        }));

                        return toPromise(forward(op));
                      });
                    }, Promise.resolve())
                    .then((_) => {
                      requestForRefetch.length = 0;
                      isRefreshing = false;
                    });
                }

                throw new Error();
              });

              // apolloClient
              //   .mutate({
              //     mutation: REFRESH_TOKEN,
              //     context: {
              //       headers: {
              //         Authorization: `Refresh ${refreshToken}`,
              //         "Content-Type": "application/json",
              //       },
              //     },
              //   })
              //   .then((response) => {
              //     console.log(response)
              //     if (response) {
              //       const tokensData = {
              //         accessToken: response?.tokenRefresh?.token?.accessToken,
              //         refreshToken: response?.tokenRefresh?.token?.refreshToken,
              //       };

              //       return signIn(tokensData);
              //     }

              //     return redirectAndLogOut(key, signOut);
              //   })
              //   .catch((_) => {
              //     redirectAndLogOut(key, signOut);
              //   });
            }

            if (
              (result === 1 && operation.operationName !== "Club") ||
              result === 6
            ) {
              redirectAndLogOut(key, signOut);
            }
          }
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, ${result} operation ${operation.operationName}`
          );
        }
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // try {
        //   const parsedError = JSON.parse(networkError.bodyText);
        //   console.log(`[Network error]: ${parsedError}`);
        // } catch (e) {
        //   // If not replace parsing error message with real one
        //   networkError.message = networkError.bodyText;
        //   console.log(`[Network error]: ${networkError}`);
        // }
      }
    }
  );

  // const refreshHandler = async (refreshToken, signIn) => {
  //   const client = new ApolloClient({
  //     ssrMode: isServer(),
  //     link: from([errorLink, httpLink]),
  //     cache,
  //   });

  //   try {
  //     const { data = {} } = await client.mutate({
  //       mutation: REFRESH_TOKEN,
  //       context: {
  //         headers: {
  //           authorization: `Refresh ${refreshToken}`,
  //         },
  //       },
  //     });

  //     console.log(data, "REFRESH");

  //     const accessToken = data?.tokenRefresh?.token?.accessToken || "";

  //     const tokensData = {
  //       accessToken,
  //       refreshToken: data?.tokenRefresh?.token?.refreshToken,
  //     };

  //     if (!isServer())
  //       window.localStorage.setItem(
  //         "tokens",
  //         JSON.stringify({
  //           ...tokensData,
  //           isAuthorized: true,
  //         })
  //       );

  //     signIn(tokensData);

  //     return accessToken;
  //   } catch (err) {
  //     // localStorage.clear();
  //     console.log("error handler token");
  //     redirectAndLogOut(key, signOut);
  //     return;
  //     // return redirectAndLogOut(key,signOut);
  //   }
  // };

  // const baseOptions = { errorPolicy: "all" };
  const client = new ApolloClient({
    defaultOptions: {
      // watchQuery: baseOptions,
      // watchQuery: { nextFetchPolicy: "cache-first" },
      // query: { fetchPolicy: "network-only", },
      // mutate: baseOptions,
    },
    ssrMode: isServer(),
    link: from([retryLink, errorLink, authLink, httpLink]), //refreshLink, handlerLink
    cache,
  });
  //   client.mutate({
  //     mutation: "re",
  // context:{}
  //     refetchQueries: [{ query: GET_INSTRUCTORS }, "Instructors"],
  //   });

  return client;
};

const promiseToObservable = (promise) => {
  return new Observable((observer) => {
    promise.then(
      (value) => {
        if (observer.closed) return;

        observer.next(value);
        observer.complete();
      },
      (err) => {
        observer.error(err);
      }
    );
  });
};

export const initializeApollo = (initialState) => {
  const club = keyClub();
  const language = pageLanguage();

  if (club && initialState && !initialState.key) keyClub(initialState.key);
  if (initialState && language != initialState.locale) {
    pageLanguage(initialState.locale);
  }
  // console.log("d", language, initialState?.locale);

  if (
    (initialState?.key !== "null" && !initialState?.key ^ !club) ||
    (initialState && language !== initialState?.locale)
    //!initialState?.key &&
  ) {
    apolloClient = null;
  }

  const _apolloClient = apolloClient ?? createApolloClient(initialState);

  // Reuse client on the client-side

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  if (isServer()) return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export function addApolloState(client, pageProps) {
  pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const { club, locale } = pageProps;

  if (club) state && (state.key = club);
  if (locale) state && (state.locale = locale);

  const auth = useAuth();

  if (auth) state && (state.auth = auth);

  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
