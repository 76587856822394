import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { resetNotification } from "../../../utils/redux/actionCreators/actions";
import AlertBlock from "../AlertBlock/AlertBlock";
import { NOSUCCESS_SVG } from "../../../constants/svgConstants";

const Notification = () => {
  const { isError, notification } = useSelector(
    ({ clubNotification }) => clubNotification
  );

  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(resetNotification());
  }, [dispatch]);

  return (
    isError && (
      <AlertBlock onClose={onClose}>
        <div className="alert-block__wrapper">
          {NOSUCCESS_SVG}
          <br />

          {notification.map((message, idx) => (
            <p key={idx} className={"alert-msg"}>
              {message}
            </p>
          ))}
        </div>
      </AlertBlock>
    )
  );
  // <div className="alert-modal">
  //   <div className="alert-block">
  //     <div className="alert-block__close-button" onClick={resetNotification}>
  //       <div className="alert-block__close-dash1"></div>
  //       <div className="alert-block__close-dash2"></div>
  //     </div>

  //     <div className="alert-block__wrapper">
  //       {clubNotification.notification &&
  //         clubNotification.notification.map(({ message }, idx) => (
  //           <span key={idx} className={"alert-msg"}>
  //             {message}
  //           </span>
  //         ))}
  //     </div>
  //   </div>
  // </div>
};

Notification.propTypes = {
  clubNotification: PropTypes.object,
  resetNotification: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func,
};

Notification.defaultProps = {
  children: "",
  onClose: () => {},
};

export { Notification };
