import React, { memo } from "react";
import { useSelector } from "react-redux";

import dynamic from "next/dynamic";
import { useRouter } from "next/router";

const SignUpSection = dynamic(() => import("../SignUp/SignUpSection"));

export const SignUpModal = memo(() => {
  const { showAuthorization } = useSelector(({ authorization = {} }) => ({
    showAuthorization: authorization.showAuthorization,
  }));

  const router = useRouter();
  const { asPath } = router;

  const isConfirmationPage = asPath.includes("/confirmation");

  return !isConfirmationPage && showAuthorization && <SignUpSection />;
});
