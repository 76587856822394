import { gql } from "@apollo/client";

export const GET_CLUBS_SLUG = gql`
  query Clubs($status: Int, $clientSiteEnabled: Boolean) {
    clubs(status: $status, clientSiteEnabled: $clientSiteEnabled) {
      slug
      # title
      # titleShort
    }
  }
`;

export const GET_PORTAL_SLUG = gql`
  query Portals {
    portals {
      slug
      clubs {
        id
      }
    }
  }
`;

export const GET_PORTAL = gql`
  query Portal($slug: String!) {
    portal(slug: $slug) {
      slug
      title
      image
      # imageTitleEnabled

      description
      descriptionHtml

      descriptionHeader

      primaryColor: colorPrimaryBackground
      secondaryColor: colorSecondaryBackground
      primaryTextColor: colorPrimaryText
      secondaryTextColor: colorSecondaryText

      icon
      iconTouch
      tagTitle
      ogType
      ogSiteName
      ogTitle
      ogDescription
      ogImage
      ogImageWidth
      ogImageHeight
      ogImageAlt
      ogImageType
      # googleTag
      clubs {
        id
        icon
        slug
        title
        description
        descriptionHtml
      }
    }
  }
`;

export const GET_CLUB_PAGE_OPTIONS = gql`
  query Club {
    club {
      id
      clientSiteEnabled
      # title
      # titleShort
    }
  }
`;

export const GET_CLUB_BY_ACTIVITY = gql`
  query Club($activity: Int) {
    club(activityId: $activity) {
      id
      halls {
        id
        title
        timeClose
        phone
        instructors {
          id
          firstName
          lastName
          isInstructorVisible
        }
        activities {
          id
          title
        }
        zones(zoneType: 2) {
          id
          order
          title
          zoneType
        }
        templates: eventtemplates {
          id
          title
        }
      }
    }
  }
`;

// export const FRAGMENT_CLUB_OFFER_FIELDS = gql`
//   fragment ClubOfferFields on ClubType {
//     discoverClubChoices {
//       id
//       title
//     }
//     discoverClubRequired
//     rules
//   }
// `;

export const FRAGMENT_CLUB_COMMON_FIELDS = gql`
  fragment ClubCommonFields on ClubType {
    cardEnabled
    clubScheduleEnabled
    blogEnabled
    announceEnabled
    storeEnabled
    cardOpenPriceEnabled: cardOpenpriceEnabled

    languageDefault
    languages

    primaryColor
    primaryTextColor
    secondaryColor
    secondaryTextColor
    phone
    address
    company
    showCompanyDetails
    offerEnabled
    icon
    iconTouch
    tagTitle
    ogType
    ogSiteName
    ogTitle
    ogDescription
    ogImage
    ogImageWidth
    ogImageHeight
    ogImageAlt
    ogImageType
    googleTag
  }
`;

export const FRAGMENT_CLUB_HEADER_DATA = gql`
  fragment ClubHeaderData on ClubType {
    scheduleDefault
    currencyTitle
    # veteranPeriod
    cashbackLimit
    # phone
    pilotEnabled
    discoverClubChoices {
      id
      title
    }
    discoverClubRequired
  }
`;

export const GET_CLUB_HEADER_DATA = gql`
  ${FRAGMENT_CLUB_COMMON_FIELDS}
  ${FRAGMENT_CLUB_HEADER_DATA}
  query Club($showOffer: Boolean!) {
    club {
      ...ClubCommonFields
      ...ClubHeaderData

      # ...ClubOfferFields @include(if: $showOffer)
      rules @include(if: $showOffer)
    }
  }
`;

export const GET_CLUB_CONFIDENTIAL_AGREEMENT = gql`
  ${FRAGMENT_CLUB_COMMON_FIELDS}
  ${FRAGMENT_CLUB_HEADER_DATA}
  query Club {
    club {
      ...ClubCommonFields
      ...ClubHeaderData
      confidential
    }
  }
`;

export const GET_CLUB_ACTIVITIES = gql`
  query Club {
    club {
      activities {
        id
        order
        slug
        title
        image
        description
      }
    }
  }
`;

export const GET_CLUB = gql`
  query Club {
    club {
      city {
        latitude
        longitude
      }
      title
      titleShort

      portal {
        id
        slug
        clubs {
          id
        }
      }
      # clubVisible
      languageDefault
      languages
      hallShowEnabled
      hallMapEnabled
      tagTitle
      tagDescription
      tagKeywords
      ogType
      ogSiteName
      ogTitle
      ogDescription
      ogImage
      ogImageWidth
      ogImageHeight
      ogImageAlt
      ogImageType
      phone
      address
      company
      showCompanyDetails
      offerEnabled
      image
      imageTitleEnabled
      icon
      iconTouch

      currencyTitle
      # veteranPeriod
      scheduleDefault
      clubStatus
      description

      descriptionHeader
      descriptionHtml
      discoverClubRequired
      primaryColor
      primaryTextColor
      secondaryColor
      secondaryTextColor

      halls {
        id
        image
        latitude
        longitude
        title
        address
        phone
        description
        offerEnabled
        imageTitleEnabled
      }
      # activities {
      #   id
      #   slug
      #   title
      #   image
      #   description
      # }
      advantages {
        description
        image
        title
        id
      }

      activities {
        id
        order
        slug
        title
        image
        description
      }
      discoverClubChoices {
        id
        title
      }

      blogEnabled
      announceEnabled
      storeEnabled
      cardEnabled
      pilotEnabled
      clubScheduleEnabled
      cardOpenPriceEnabled: cardOpenpriceEnabled
      contactFacebook
      contactInstagram
      contactYoutube
      contactTelegram
      contactLinkedin
      contactViber
      contactTiktok
      cashbackLimit
      googleTag
      facebookPixel
    }
  }
`;

export const GET_HALL_OFFER = gql`
  query Hall($id: Int!) {
    offerData: hall(id: $id) {
      id
      offer
    }
  }
`;

export const GET_CLUB_OFFER = gql`
  query Club {
    offerData: club {
      id
      offer
    }
  }
`;

export const GET_FAMILY = gql`
  query Family {
    family {
      id
      avatar
      phone
      email
      firstName
      lastName
      birthday
      gender
      manager
      super
      instructor
      relation
      relationTitle
    }
  }
`;
