import { useLazyQuery } from "@apollo/client";

import { GET_ALL_USER_DATA, GET_USER } from "../graphql/queries/queriesUser";
import { useAuth } from "../utils/auth";

import { familyData, rulesData, userData } from "../graphql/reactivities";
import { useEffect } from "react";

const useUser = ({ skip = false }) => {
  const { accessToken, isAuthorized, isReady } = useAuth();

  const [getUser] = useLazyQuery(GET_USER, {
    // context: {
    //   headers: {
    //     authorization: `Token ${"rrrrr"}${"test" ? ` ${"test"}` : ""}`,
    //   },
    // },
    fetchPolicy: "network-only",
    // fetchPolicy: "cache-and-network",

    onCompleted: ({ user }) => {
      // console.log("user", user);

      userData(user);
      const {
        phone,
        firstName,
        email,
        phoneConfirmed,
        emailConfirmed,
        passwordConfirmed,
      } = user;

      const userStorageData = {
        phone,
        firstName,
        email,
        phoneConfirmed,
        emailConfirmed,
        passwordConfirmed,
      };

      localStorage.setItem("userData", JSON.stringify(userStorageData));
    },
  });

  const [getAllUserData, { error: rulesError }] = useLazyQuery(
    GET_ALL_USER_DATA,
    {
      fetchPolicy: "network-only",

      onCompleted: ({ profile, family = [] }) => {
        // console.log("profile", profile);
        rulesData(profile?.rulesAccepted ?? false);

        familyData(family);
      },

      onError: ({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.result === 7) {
          rulesData(false);
          familyData([]);
        }
      },
    }
  ); //{ loading: rulesChecking, error: rulesError }

  useEffect(() => {
    if (skip && isAuthorized) {
      getUser();
      return;
    }

    if (!skip && isAuthorized) {
      getUser();
      getAllUserData();
      return;
    }

    if (isReady && !isAuthorized) {
      const {
        phone,
        firstName,
        email,
        phoneConfirmed,
        emailConfirmed,
        passwordConfirmed,
      } = userData();

      const user = {
        phone,
        firstName,
        email,
        phoneConfirmed,
        emailConfirmed,
        passwordConfirmed,
      };

      userData(user);
      rulesData(false);
      familyData([]);

      localStorage.setItem("userData", JSON.stringify(user));
    }
  }, [isReady, isAuthorized, skip, accessToken]);

  return { isAuthorized, isReady, rulesError }; // rulesChecking,
};

export default useUser;
